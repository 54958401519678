<template>
  <div class="commercial">
    <headert></headert>
    <div class="banner">
      <img src="../../assets/image/personnel/banner2.png" alt />
    </div>
    <navMenu :activeIndex='menuActiveIndex' :menu='menu' :breadcrumb='breadcrumb' />
    <el-row class=" WJgroupIntroduce-Box contentBG" type="flex" justify="center" align='center'>
      <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
        <div class="comContent">
          <div class="comConntentLe">
            <h2>加入维佳</h2>
            <p class="line"></p>

            <div>
              <router-link class="conlist2" tag="a" to="/personnel">
                <span class="font">人才理念</span>
                <i class="el-icon-plus"></i>
              </router-link>
            </div>
            <div class="conlist1">
              <div class="commercialAdd">
                <span class="font">人才战略</span>
                <!-- <i class="el-icon-plus"></i> -->
              </div>
              <h3>Talent Strategy</h3>
              <span class="thank"> 严控进口标准<br />
                重视培训管理，实现自我循环，将人员变人才</span>
            </div>

            <div>
              <router-link class="conlist2" tag="a" to="/personnelJoinUs">
                <span class="font">加入我们</span>
                <i class="el-icon-plus"></i>
              </router-link>
            </div>
          </div>
          <div class="comContentRi">
            <div class="house flex">
              <div class="num">2</div>
              <div class="numRe">
                <div class="com flex">
                  <h2 class="qualityHouse">人才战略</h2>
                  <span class="eng">Talent Strategy</span>
                </div>
                <p>严控进口标准，重视培训管理，实现自我循环，将人员变人才。</p>
              </div>
            </div>

            <div class="strategy-content">
              <el-carousel indicator-position="outside" height="500px" :interval='10000000' arrow='never'>
                <el-carousel-item v-for="item in 4" :key="item">
                  <div class="strategy-conOne">
                    <div class="strategy-conOimg">
                      <img src="../../assets/image/personnel/img5.png" alt="">
                      <div class="strategy-conOnefont">
                        <h3>人才·战略·方针</h3>
                        <p>
                          维佳集团始终坚持将人才建设作为重难点问题研究，沿着“严控进口标准，重视培训管理，实现自我循环结构年轻化，将人员变人才”的总战略方针，围绕“选人标准、能力培养、思想武装、作风建设、团队管理”五大核心基础，利用“进人关、育人关、用人关”把控过程，通过不断加强宏观指导、健全人才工作载体、实施干部培训工程、创新人才工作机制、深入推进人才兴业战略，立志提高集团上下人才工作水平，打造一支有格局、有情怀有品位的干部队伍。
                        </p>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
        </div>
      </el-col>
    </el-row>
    <footerb></footerb>
  </div>
</template>
<script>
  export default {
    name: "WJgroupIntroduce",
    components: {},
    data() {
      return {
        menuActiveIndex: '/personnel',
        menu: {
          name: '人才招聘',
          path: '/personnel',
          list: [{
            name: '加入维佳',
            path: '/personnel',
          }, {
            name: '联系方式',
            path: '/personnelContact',
          }]
        },
        breadcrumb: [{
          name: '人才招聘',
          path: '/personnel',
        }, {
          name: '加入维佳',
          path: '/personnel',
        }, {
          name: '人才战略',
          path: '/personnelStrategy',
        }]
      };
    },
    computed: {},
    created() {},
    methods: {
      onSwiper(swiper) {
        console.log(swiper);
      },
      onSlideChange() {
        console.log("slide change");
      }
    }
  };
</script>
<style lang="less" scoped>
  .commercial {
    .banner {
      width: 100%;
      height: 550px;

      img {
        width: 100%;
        height: 550px;
      }
    }

    .comContent {
      width: 100%;
      margin: 0 auto;
      display: flex;


      .comConntentLe {
        width: 312px;
        height: 422px;

        h2 {
          width: 146px;
          height: 36px;
          margin-top: 54px;
          font-size: 36px;
          line-height: 36px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #202c38;
        }

        .line {
          width: 33px;
          margin-top: 22px;
          height: 3px;
          background: #1f2d3a;
        }

        h3 {
          margin-top: 57px;
          height: 20px;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #1f2d3a;
          line-height: 20px;
          margin-bottom: 21px;
        }

        .conlist2 {
          height: 69px;
          line-height: 69px;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #cccc;
          margin-right: 9px;

          .font {
            height: 20px;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #1f2d3a;
          }

          ::v-deep .el-icon-plus {
            margin-top: 27px;
            width: 14px;
            height: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #aaaaaa;
            line-height: 20px;
          }
        }

        .conlist1 {
          border-bottom: 1px solid #cccc;
          padding: 20px 0;

          .commercialAdd {
            margin-right: 9px;
            margin-top: 26px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 28px;

            span {
              height: 28px;
              font-size: 28px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #af8e68;
              line-height: 28px;
            }

            i {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #aaaaaa;
              // line-height: 14px;
            }
          }

          h3 {
            display: block;
            margin-top: 9px;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 300;
            color: #aaaaaa;
            line-height: 20px;
          }

          .thank {
            display: block;

            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #202c38;
            line-height: 24px;
          }
        }
      }

      .comContentRi {
        // width: 985px;
        flex: 1;
        // height: 1582px;

        margin-top: 150px;
        margin-bottom: 150px;
        background-color: #fcfafa;
        padding: 30px 100px;

        .house {
          .num {
            font-size: 93px;
            font-family: Impact;
            font-weight: 400;
            color: #af8e68;
          }

          .numRe {
            margin-left: 30px;
          }

          .com {
            display: flex;

            .qualityHouse {
              font-size: 36px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #1f2d3a;
              line-height: 40px;

            }

            .eng {
              height: 21px;
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: 300;
              color: #aaaaaa;
              margin-left: 11px;
            }
          }

          p {

            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #1F2D3A;
            line-height: 24px;
            margin-top: 10px;

          }
        }
      }
    }



  }

  .personnel_ul {
    width: 100%;

  }

  .personnel_ul li {
    margin: 20px 0;
    padding: 5px;
    background: #FFFFFF;
    border: 1px solid #F6EFE5;
  }

  .personnel_ul .personnel_ul_img {
    width: 320px;
    height: 150px;
  }

  .personnel_ul .personnel_ul_img img {
    width: 100%;
    height: 100%;
  }

  .personnel_ul .personnel_ul_content {
    flex: 1;
    margin-left: 40px;
  }

  .personnel_ul .personnel_ul_content h2 {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #202C38;
    line-height: 40px;
    height: 40px;
  }

  .personnel_ul .personnel_ul_content p {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #6B7B88;
    line-height: 24px;
    height: 72px;
    width: 100%;
  }

  .strategy-content {}

  .strategy-content .strategy-conOne {
    width: 100%;

  }

  .strategy-conOimg {
    width: 100%;
    height: 350px;
    position: relative;

    img {
      width: 100%;
    }
  }

  .strategy-conOnefont {
    position: absolute;
    top: 248px;
    left: 10%;
    background-color: #fff;
    width: 80%;
  }

  .strategy-conOnefont h3 {
    width: 80%;
    margin: 30px auto 10px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #202C38;
    line-height: 30px;
  }

  .strategy-conOnefont p {
    width: 80%;
    margin: 0px auto 30px;

    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #6B7B88;
    line-height: 24px;
    height: 120px;
    overflow: hidden;
  }

  ::v-deep .el-carousel__indicator.is-active button {
    background-color: #AF8E68;
  }
</style>
